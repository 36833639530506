
.wnd_react_map_overlay_container {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    border-radius: 3px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px
}

.wnd_react_map_overlay_container:after, .wnd_react_map_overlay_container:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.wnd_react_map_overlay_container:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.wnd_react_map_overlay_container:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.wnd_react_map_overlay_title {
    font-size: 1.2em;
    padding: 16px;
}

.wnd_react_map_overlay_title_content {

}

.wnd_react_map_overlay_title_closer {
    margin-left: 20px;
    float: right;
}

.wnd_react_map_overlay_title_closer:hover {
    cursor: pointer
}

.wnd_react_map_overlay_content {
    clear: both;
    font-size: 1em;
    padding: 16px;
}
