/*
 * CSS styles for the whole app will be placed here.
 */

html {
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
    color: #282828;
    font-size: 16px;
    -webkit-overflow-scrolling: touch;
}

html, body {
    /*position: fixed;
    overflow: hidden;*/
}

html, body, #root {
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

#root {
    /*overflow-y: scroll;*/
    -webkit-overflow-scrolling: touch;
}

/**
 * When printing allow the content to overflow.
 */
@media print {
    html, body {
        position: relative;
        overflow: auto;
    }

    html, body, #root {
        height: auto;
    }
}

/**
 * Box sizing reset.
 */
*, *:before, *:after {
    box-sizing: inherit;
}

/**
 * Reposition the zoom to the bottom-right.
 */
.ol-zoom {
    top: 8px;
    left: auto;
    bottom: auto;
    right: 8px;
}

/**
 * set custom rotate styles for the map
 */
.ol-rotate {
    left: 8px;
    transition: opacity .25s linear, visibility 0s linear;
    background-color: transparent;
    right: auto;
}

/**
 * Hide the attribution thing on the map.
 */
.ol-attribution {
    display: none;
}

/**
 * Hide the zoom control on the map.
 */
.ol-scale-line {
    top: auto;
    left: auto;
    right: 8px;
    bottom: 8px;
}

/**
 * Hide the zoom control on the map.
 */
@media screen and (max-width: 800px), screen and (max-height: 600px) {

    .ol-zoom {
        display: none;
    }

    .ol-scale-line {
        top: auto;
        left: auto;
        right: 8px;
        bottom: 8px;
    }
}


/**
 * This class is added/removed to the body when hovering a feature. See SitesLayer.
 */
.hand {
    cursor: pointer;
}



