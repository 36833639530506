/**
 * Cursor tooltip while drawing
 */
.wnd_react_map_measure_tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 8px 8px 2px;
    opacity: 0.8;
    white-space: nowrap;
    font-size: .85rem;
    display: flex;
    flex-direction: row;
}

.wnd_react_map_measure_tooltip_static {
    background-color: #FFCC33;
    color: black;
    border: 1px solid white;
}

.wnd_react_map_measure_tooltip_measurement {
    opacity: 1;
}

.wnd_react_map_measure_tooltip_measurement:before,
.wnd_react_map_measure_tooltip_static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.7);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.wnd_react_map_measure_tooltip_static:before {
    border-top: 6px solid rgba(255, 204, 51, 0.9);
}

.wnd_react_map_measure_tooltip_closer {
    padding-left: 20px;
    font-size: 1.0rem;
    color: black;
    flex: 0;
}

.wnd_react_map_measure_tooltip_closer:hover {
    cursor: pointer;
    font-weight: bold;
}

.wnd_react_map_measure_tooltip_body {
    flex: 1;
}
